// export { barrister_shortlist };

const $ = jQuery.noConflict();

const barrister_shortlist = {
    init() {
        const $ = jQuery.noConflict();

        const savedBarristers = JSON.parse(window.localStorage.getItem("barrister_shortlist"));

        const ADDED_CLASS = "shortlisted";

        let state = {
            barristers: savedBarristers || [],
        };

        function updateLocalStorage() {
            window.localStorage.setItem("barrister_shortlist", JSON.stringify(state.barristers));
        }

        function checkAddedBarristers() {
            // Barrister Grid
            $('.barrister').removeClass(ADDED_CLASS);
            $(`.barrister-favourite`).removeClass(ADDED_CLASS);
            // Barrister Profile
            $(`.button-wrapper .barrister-favourite`).removeClass(ADDED_CLASS);
            // Check if members are on the list, and if they are add a class
            state.barristers.map((e) => {
                // Toggle favourite
                $(`.barrister[id=${e.id}]`).addClass(ADDED_CLASS);
                $(`.barrister[id=${e.id}] .barrister-favourite`).addClass(ADDED_CLASS);
            });
        }

        function updateCounter() {
            state.barristers.length >= 1 ? $('#shortlist_counter').addClass('open') : $('#shortlist_counter').removeClass('open')
            $('#sidebar #shortlist_counter span').text(state.barristers.length);
        }

        function generateBarristerList() {
            $('#barrister_shortlist .shortlist').html("").append(
                state.barristers.map(
                    (item) => 
                    `<div class="barrister ${ADDED_CLASS}" id="${item.id}">
                        <h4 class="name">${item.name}</h4>
                        <a class="download" href="/pdf/download?barrister=${item.id}" download>Download CV <img src="/assets/images/icons/cv-download.svg" alt="Download icon" /></a>
                        <img onclick="toggleBarristerDetails('${item.id}','${item.name}','${item.email}','${item.call}','${item.silk}','${item.profile}')" class="remove-barrister" alt="X" src="/assets/images/icons/remove.svg" />
                    </div>`
                )
            )
            $('#barrister_shortlist #shortlist_export').attr(
                "href",
                "/pdf/download?barrister=" + state.barristers.map(
                    (item) => `${item.id}`)
            )
        }

        function addBarrister(
            id = "",
            name = "",
            email = "",
            call = "",
            silk = "",
            profile = ""
        ) {
            const exists = (element) => element.id === id;
            const barrister = {id, name, email, call, silk, profile};

            return state.barristers.some(exists)
                ? null
                : ((state.barristers = [...state.barristers, barrister]),
                    updateLocalStorage(),
                    checkAddedBarristers(),
                    updateCounter(),
                    generateBarristerList()
                );
        }
        function removeBarrister (id) {
            const filteredBarristers = state.barristers.filter((p) => p.id !== id);
            state.barristers = filteredBarristers;
            updateLocalStorage();
            checkAddedBarristers();
            updateCounter();
            generateBarristerList();
            // Barrister Grid
            $(`.barrister[id=${id}]`).removeClass(ADDED_CLASS);
            $(`.barrister[id=${id}] .barrister-favourite`).removeClass(ADDED_CLASS);
            // Barrister Profile
            $(`.button-wrapper .barrister-favourite`).removeClass(ADDED_CLASS);
        }


        // Global Functions
        window.checkAddedBarristers = function () {
            checkAddedBarristers();
            updateCounter();
            generateBarristerList();
        }
        window.toggleBarristerDetails = function (
            id,
            name,
            email,
            call,
            silk,
            profile
        ) {
            $(`.barrister[id=${id}]`).hasClass(ADDED_CLASS) ? removeBarrister(id) : addBarrister(id,name,email,call,silk,profile)
        }
        window.clearBarristerDetails = function () {
            state.barristers = [];
            window.localStorage.clear("barrister_shortlist");
            updateCounter();
            generateBarristerList();
            checkAddedBarristers();
            $('#barrister_shortlist').removeClass('open');
        }
    },
}